import React from 'react';
import style from './cover.module.scss';

export const Cover = ({info}) => (
    <div className={style.coverContainer}>
        <img src={info.coverImage} alt="Cover" className={style.coverImg} />
        <div className={style.shadow}></div>
        <div className={style.infoContainer}>
            <img src={process.env.PUBLIC_URL + '/assets/logo_complete.png'} alt="logo" className={style.logo} />
            <h1 className={style.coverTitle}>{info.coverTitle}</h1>
            <div className={style.btnContainer}>
                <button className={style.btnContact}>Contáctame</button>
                <a className={style.btnMore} href="#more">Saber más</a>
            </div>
        </div>
    </div>
)