import React from 'react';
import style from './descriptions.module.scss';
import { Layout } from '../Layout';

export const Descriptions = ({info}) => (
    <div className={style.descriptionsContainer}>
            <Layout>
                <div className={style.grid}>
                    <div className={style.description}>
                        <h3>{info.titleDescriptionOne}</h3>
                        <div className={style.border}></div>
                        <p>{info.descriptionOne}</p>
                    </div>
                    <div className={style.description}>
                        <h3>{info.titleDescriptionTwo}</h3>
                        <div className={style.border}></div>
                        <p>{info.descriptionTwo}</p>
                    </div>
                    <div className={style.description}>
                        <h3>{info.titleDescriptionThree}</h3>
                        <div className={style.border}></div>
                        <p>{info.descriptionThree}</p>
                    </div>
                    <div className={style.description}>
                        <h3>{info.titleDescriptionFour}</h3>
                        <div className={style.border}></div>
                        <p>{info.descriptionFour}</p>
                    </div>
                </div>
            </Layout>
    </div>
)