import React from 'react';
import style from './aboutMe.module.scss';
import { ImQuotesLeft } from 'react-icons/im'

import { Layout } from '../Layout';

export const AboutMe = ({info}) => (
    <div className={style.container}>
        <Layout>
            <div className={style.aboutContainer}>
                <div className={style.left}>
                    <img src={info.aboutMeImage} alt="José Flores" />
                </div>
                <div className={style.right}>
                    <ImQuotesLeft size={30} color="gray" />

                    <div className={style.aboutDescription} dangerouslySetInnerHTML={{__html: info.aboutMeDescription}}></div>

                    <h2>{info.aboutMeName}</h2>
                    <p className={style.trainingTitle}>
                        {info.aboutMeTitle}
                    </p>
                </div>
            </div>
        </Layout>
    </div>
)