import React, { useRef } from 'react';
import style from './transformation.module.scss';
import ScrollContainer from 'react-indiana-drag-scroll';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { Layout } from '../Layout';

export const Transformations = ({info}) => {

    const drag = useRef(null);

    const getRefScroll = (element) => {
        drag.current = element;
    }

    const moveToRight = () => {
        drag.current.scrollLeft += 300;
    }

    const moveToLeft = () => {
        drag.current.scrollLeft -= 300;
    }

    return (
        <div className={style.transformationsContainer}>
            <Layout background="black">
                <h2 className={style.title}>{info.titleTransformation}</h2>

                <div className={style.scroll}>

                    <div className={style.arrow} style={{left: '20px'}} onClick={moveToLeft}>
                        <MdKeyboardArrowLeft color="black" size={30} />
                    </div> 

                    <div className={style.arrow} style={{right: '20px'}} onClick={moveToRight}>
                        <MdKeyboardArrowRight color="black" size={30} />
                    </div> 
                    <ScrollContainer className={style.scrollContainer} innerRef={getRefScroll}>
                        {
                            info.users.map(user => (
                                <div className={style.transformation} key={user.idUser}>
                                    <img src={user.imgBefore} alt="Transformación" className={style.transformation_img} />
                                    <img src={user.imgAfter} alt="Transformación" className={style.transformation_img} />

                                    <div className={style.transformation_footer}>
                                    <div className={style.transformation_footer}>
                                        <div className={style.left}>
                                            <img src={process.env.PUBLIC_URL + '/assets/street_white.png'} alt="Img" />
                                            <div className={style.triangle_down_left}></div>
                                        </div>

                                        <div className={style.right}>
                                            <p>{user.name + ' ' + user.lastName}</p>
                                            <img src={process.env.PUBLIC_URL + '/assets/logo_black.png'} alt="Img" />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            ))
                        }
                    </ScrollContainer>
                </div>
    
            </Layout>
        </div>
    )
}