import React from 'react';
import { Layout } from '../Layout';
import style from './footer.module.scss';

export const Footer = () => (
    <div className={style.footerContainer}>
        <Layout background="black">
            <div className={style.footerInfo}>
                <p className={style.copy}>
                    Copyright {new Date().getFullYear()} &copy; STREETGYM - José Flores. Todos los derechos reservados
                </p>
                <img src={process.env.PUBLIC_URL + '/assets/log_white.png'} alt="Logo"/>
            </div>
        </Layout>
    </div>
)