import React from 'react';
import { Layout } from '../Layout';
import style from './principal.module.scss';

export const PrincipalINfo = ({info}) => (
    <div className={style.principalContainer} id="more">
        <Layout background="black">
            <div className={style.sectionOne}>
                <div className={style.imgSection}>
                    <img src={info.principalSectionOneImage} alt="Img" />
                </div>

                <div className={style.sectionText}>
                    <h2>{info.principalInfoTitle}</h2>
                    <p>{info.principalSectionOne}</p>
                </div>
            </div>

            <div className={style.sectionTwo}>
                <div className={style.sectionText}>
                    <p>{info.principalSectionTwo}</p>
                </div>

                <div className={style.imgSection}>
                    <img src={info.principalSectionTwoImage} alt="Img" />
                </div>
            </div>
        </Layout>
    </div>
)