/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import Axios from 'axios';
import './App.css'
import { config } from './config';

import { Header } from './components/Header';
import { Cover } from './components/Cover';
import { PrincipalINfo } from './components/PrincipalInfo';
import { Descriptions } from './components/Descriptions';
import { Join } from './components/Join';
import { Focus } from './components/Focus';
import { Transformations } from './components/Transformations';
import { AboutMe } from './components/AboutMe';
import { Price } from './components/Price';
import { Footer } from './components/Footer';

const App = () => {
  const [landingInfo, setLandingInfo] = useState(null);

  useEffect(() => {
    Axios.get(`${config.baseURL}/landing`).then(({data}) => {
      setLandingInfo(data.data);
    })
  }, [])

  return (
    <Fragment>
      <Header />
      {
        landingInfo && 
        <Fragment>
          <Cover info={landingInfo} />
          <PrincipalINfo info={landingInfo} />
          <Descriptions info={landingInfo} />
          <Join />
          <Focus info={landingInfo} />
          <Transformations info={landingInfo} />
          <AboutMe info={landingInfo} />
          <Price info={landingInfo} />
          <Footer />
        </Fragment>
      }
    </Fragment>
  );
}

export default App;
