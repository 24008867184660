import React from 'react';
import style from './header.module.scss'
import { Layout } from '../Layout';

export const Header = () => {

    return(
        <Layout background="black">
            <div className={style.headerContainer}>
                <a href="https://app.streetgymprogram.com">Iniciar sesión <span>→</span></a>
            </div>
        </Layout>
    );
}