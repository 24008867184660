import React from 'react';
import style from './focus.module.scss';

import { Layout } from '../Layout';

export const Focus = ({info}) => (
    <div className={style.focusContainer}>
        <Layout background="white">
            <div className={style.infoContainer}>
                <div className={style.focusTitle}>
                    <h2>{info.titleFocus}</h2>
                    <div className={style.border}></div>
                </div>
                <div className={style.section}>
                <img src={info.focusImageOne} alt="img" />

                <p>{info.focusOne}</p>
                </div>

                <div className={style.section}>
                    <p>{info.focusTwo}</p>

                    <img src={info.focusImageTwo} alt="img" />
                </div>
            </div>
        </Layout>
    </div>
);