import React from 'react';
import style from './join.module.scss';

import { Layout } from '../Layout';

export const Join = () => (
    <div className={style.joinContainer}>
        <Layout background="black">
            <div className={style.infoContainer}>
                <h2>Únete al programa</h2>
                <button>Únete ahora</button>
            </div>
        </Layout>
    </div>
)