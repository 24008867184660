import React from 'react';
import { Layout } from '../Layout';
import style from './price.module.scss';

import { MdAttachMoney } from 'react-icons/md';

export const Price = ({info}) => (
    <div className={style.priceContainer}>
        <Layout background="black">
            <div className={style.infoContainer}>
                <div className={style.priceContainer}>
                    <p className={style.priceText}>
                        <MdAttachMoney color="white" size={25} className={style.dollar} />
                        {info.price}
                    </p>

                    <p className={style.pay}>Pago único</p>

                    <p className={style.infoText}>
                        Cambia tu vida con solo un click
                    </p>

                    <div className={style.btnContainer}>
                        <button>Contáctame</button>
                    </div>

                </div>
            </div>
        </Layout>
    </div>
)